<template>
  <transition
    :name="transition"
    mode="out-in"
    appear
  >
    <v-sheet
      v-if="allConditions($props?.conditions)"
      :id="id || false"
      tag="section"
      :class="['section', safeVariant, 'w-100', 'd-flex', 'overflow-hidden', styles]"
      v-bind="$attrs"
    >
      <v-row
        no-gutters
        class="wrapper w-100 justify-inherit align-inherit"
      >
        <v-col
          v-if="hasMedia"
          cols="12"
          :class="[`${safeVariant}-media`, 'position-relative', 'layer-background', media?.styles]"
        >
          <mtf-media :items="media.items" />
        </v-col>

        <v-col
          v-if="hasContent || hasActions || hasOrganisms"
          cols="12"
          :class="[
            `${safeVariant}-content`,
            'position-relative',
            'layer-content',
            'd-flex',
            'flex-column',
            content?.styles
          ]"
        >
          <mtf-content
            v-if="hasContent"
            :variant="safeVariant"
            v-bind="content"
            :styles="false"
          />

          <!-- actions -->
          <mtf-actions
            v-if="hasActions"
            :actions="actions.items"
            :class="[`${safeVariant}-actions`, actions?.styles]"
          />

          <!-- organism slot -->
          <div
            v-if="hasOrganisms"
            :class="[`${safeVariant}-organisms`, organisms?.styles]"
          >
            <slot name="default">
              <component
                :is="organism.template"
                v-for="(organism, index) in safeOrganisms"
                :key="`organism-${index}`"
                v-bind="safeConfig(organism.config)"
                :class="[organism?.styles]"
                @cancel="$emit('cancel')"
                @update:valid="$emit('update:valid', $event)"
                @update:model-value="$emit('update:model-value', $event)"
              />
            </slot>
          </div>
        </v-col>
      </v-row>
    </v-sheet>
  </transition>
</template>

<!-- eslint-disable vue/no-unused-components -->
<script>
import { defineAsyncComponent } from 'vue';

import { filter, identity, isEmpty, mapValues, pickBy, some, map } from 'lodash-es';

import { useCheckConditions, useValues } from '@matterific/utils';

export default defineComponent({
  name: 'MtfSection',
  components: {
    MtfMedia: defineAsyncComponent(() => import('@matterific/components/Media.vue')),
    MtfContent: defineAsyncComponent(() => import('@matterific/components/Content.vue')),
    MtfActions: defineAsyncComponent(() => import('@matterific/components/Actions.vue')),
    MtfFaqs: defineAsyncComponent(() => import('@matterific/components/Faqs.vue')),
    MtfScroller: defineAsyncComponent(() => import('@matterific/components/Scroller.vue')),
    MtfMarqueetext: defineAsyncComponent(() => import('@matterific/components/Marquee.vue'))
    //  MtfCards : defineAsyncComponent(() => import( '@matterific/components/Cards.vue')),
    //  MtfTimeline : defineAsyncComponent(() => import( '@matterific/components/Timeline.vue')),
    //  MtfCountdown : defineAsyncComponent(() => import( '@matterific/components/Countdown.vue')),
  },
  inject: {
    pageContext: { default: {} }
  },
  props: {
    //--- Settings
    id: String,
    date: [Date, String],
    variant: String,

    styles: [String, Object, Array],
    transition: { type: String, default: 'slide-y-reverse-transition' },
    //---
    media: Object,
    content: Object,
    actions: Object,
    conditions: Array,
    organisms: Object
  },
  emits: ['cancel', 'update:valid', 'update:model-value'],
  data() {
    return {};
  },
  computed: {
    safeVariant() {
      return this.variant || 'section';
    },

    safeOrganisms() {
      // todo apply confitions
      return map(filter(this.organisms?.items, 'template'), (organism) => ({
        ...organism,
        template: `${organism.templatePrefix || 'mtf'}-${organism.template}`
      }));
    },

    hasMedia() {
      return !!this.media?.items?.length;
    },

    hasContent() {
      return !isEmpty(pickBy(this.content, identity));
    },

    hasActions() {
      return some(this.actions?.items, (item) => useCheckConditions(item.conditions, item));
    },

    hasOrganisms() {
      return !!this.safeOrganisms?.length || !!this.$slots.default;
    }
  },
  methods: {
    allConditions(conditions) {
      return useCheckConditions(conditions, this?.$props);
    },

    safeConfig(config) {
      return mapValues(config, (value) => useValues(value, null, this.pageContext));
    }
  }
});
</script>

<style lang="scss" scoped>
.justify-inherit {
  justify-content: inherit;
}

.align-inherit {
  align-items: inherit;
}

.layer-content {
  transform: translate3d(0, 0, 0); // prevents animations jank
}
</style>
